(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-overview-parts/assets/javascripts/coupon-overview-reduce.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-overview-parts/assets/javascripts/coupon-overview-reduce.js');
"use strict";

const {
  useSelector
} = ReactRedux;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  useBranding
} = svs.components.tipsen.hooks;
const {
  selectReductionSelectionsForEvent
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  selectReduction
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  constants: engineConstants
} = svs.components.tipsen.engine;
const {
  Outcome
} = svs.poolBetting.components.outcome;
const LabelMap = {
  [engineConstants.ReductionScore.HOME]: {
    labelShort: 'H',
    labelFull: 'Hemmavinst'
  },
  [engineConstants.ReductionScore.DRAW]: {
    labelShort: 'O',
    labelFull: 'Oavgjort'
  },
  [engineConstants.ReductionScore.AWAY]: {
    labelShort: 'B',
    labelFull: 'Bortavinst'
  }
};
const CouponOverviewReduce = _ref => {
  let {
    eventNumber = 0,
    className = ''
  } = _ref;
  const couponId = useCouponId();
  const [productBranding] = useBranding();
  const reductionValues = useSelector(state => selectReduction(state));
  const selectedReductions = useSelector(state => selectReductionSelectionsForEvent(state, couponId, eventNumber));
  const classNames = ['pg_tipsen__outcome_row--vertical'];
  if (className) {
    classNames.push(className);
  }
  return React.createElement("div", {
    className: classNames.join(' ')
  }, reductionValues.map(value => {
    const dataTestId = "outcome_reduction_".concat(eventNumber, "_").concat(value);
    return React.createElement(Outcome, {
      ariaLabel: "Reducering ".concat(LabelMap[value].labelFull),
      branding: productBranding,
      className: "pg_overview_outcome_reduce",
      dataTestId: dataTestId,
      id: dataTestId,
      isSelected: selectedReductions.includes(value),
      key: "outcome_reduction".concat(value),
      label: LabelMap[value].labelShort
    });
  }));
};
setGlobal('svs.components.tipsen.couponOverviewParts.CouponOverviewReduce', CouponOverviewReduce);

 })(window);